import React, { FC } from 'react';

import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';

import { ILoginSocialBarProps, Layout } from '../LoginSocialBar.types';
import LoggedInSocialBar from './components/loggedInSocialBar';
import LoggedOffSocialBar from './components/loggedOffSocialBar';
import {
  BIAction,
  getMembersAreaNavigationEvent,
  MEMBERS_APP_UOU_ENDPOINT,
} from './bi/events';

import styles from './style/LoginSocialBar.scss';

const showAvatar = (displayMode: Layout) =>
  displayMode === Layout.AvatarOnly ||
  displayMode === Layout.AvatarAndText ||
  displayMode === Layout.MemberInitials;

const LoginSocialBar: FC<ILoginSocialBarProps> = props => {
  const {
    id,
    className,
    isLoggedIn,
    userName,
    loggedInText,
    logOutText,
    direction,
    useNativeMenu,
    menuItems,
    iconItems,
    currentPrimaryPageHref,
    reportBiOnClick,
    onLogin,
    onLogout,
    navigateTo,
    onMouseEnter,
    onMouseLeave,
    isMenuOpen,
    onMenuOpen,
    onMenuClose,
    reportBiOnMenuItemClick,
    translations,
    displayMode,
    avatarUri,
    iconSize,
    defaultAvatarSvgContent,
    showBellIcon,
    arrowShape,
    menuItemsAlignment,
    reportBi,
  } = props;

  const closeMenuOnClickOutside: React.FocusEventHandler = ({
    currentTarget,
    relatedTarget,
  }) => {
    if (!relatedTarget || !currentTarget.contains(relatedTarget as Node)) {
      onMenuClose();
    }
  };

  const showIcons = showBellIcon && !!iconItems.length;

  const onHover = (event: React.MouseEvent<Element, MouseEvent>) => {
    onMouseEnter?.(event);
    if (isLoggedIn && !isMenuOpen) {
      void reportBi(getMembersAreaNavigationEvent({ action: BIAction.Hover }), {
        endpoint: MEMBERS_APP_UOU_ENDPOINT,
      });
    }
  };

  const openMenu = () => {
    onMenuOpen();
    if (isLoggedIn) {
      void reportBi(
        getMembersAreaNavigationEvent({ action: BIAction.MenuClick }),
        {
          endpoint: MEMBERS_APP_UOU_ENDPOINT,
        },
      );
    }
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, styles.root)}
      tabIndex={-1}
      onBlur={
        isLoggedIn && !useNativeMenu ? closeMenuOnClickOutside : undefined
      }
      onMouseEnter={onHover}
      onMouseLeave={onMouseLeave}
    >
      {isLoggedIn ? (
        <LoggedInSocialBar
          avatarUri={avatarUri}
          isLoggedIn={isLoggedIn}
          userName={userName}
          id={id}
          displayMode={displayMode}
          iconSize={iconSize}
          defaultAvatarSvgContent={defaultAvatarSvgContent}
          loggedInText={loggedInText}
          currentPrimaryPageHref={currentPrimaryPageHref}
          direction={direction}
          menuItems={menuItems}
          iconItems={iconItems}
          showIcons={showIcons}
          isMenuOpen={isMenuOpen}
          onMenuOpen={openMenu}
          reportBiOnMenuItemClick={reportBiOnMenuItemClick}
          logOutText={logOutText}
          navigateTo={navigateTo}
          onLogout={onLogout}
          onMenuClose={onMenuClose}
          showAvatar={showAvatar(displayMode)}
          useNativeMenu={useNativeMenu}
          translations={translations}
          arrowShape={arrowShape}
          menuItemsAlignment={menuItemsAlignment}
          reportBi={reportBi}
          reportBiOnClick={reportBiOnClick}
        />
      ) : (
        <LoggedOffSocialBar
          id={id}
          displayMode={displayMode}
          iconSize={iconSize}
          defaultAvatarSvgContent={defaultAvatarSvgContent}
          isLoggedIn={isLoggedIn}
          userName={userName}
          avatarUri={avatarUri}
          showAvatar={showAvatar(displayMode)}
          handleLogin={onLogin}
          reportBiOnClick={reportBiOnClick}
          translations={translations}
        />
      )}
    </div>
  );
};

export default LoginSocialBar;
